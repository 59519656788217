<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <!-- <div class="text">商品信息管理</div> -->
          <el-input v-model="queryValue" placeholder="请输入商品名称" ></el-input>
          <el-button @click="initTable" type="primary" class="btn" icon="el-icon-search" v-auth='"productInfo:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn" v-auth='"productInfo:add"'>+ 新增</el-button>
        <el-button @click="showExport" type="primary" class="btn"><i class="iconfont iconicon-04" v-auth='"productInfo:export"'></i> 导出</el-button>
        <el-button @click="selectDel" type="primary" class="btn"><i class="iconfont iconicon-05"  v-auth='"productInfo:delete"'></i> 批量删除</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       <!-- :hideSinglePage='false' :selectionType='true'-->
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>

    <export-dialog :visible='exportVis' :cancelDialog='exportCancel' :confirmDialog="exportConfirm"  />

  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import exportDialog from './dialog/export'
  import axios from 'axios'
  export default {
    components: { delDialog ,compsTable , exportDialog},
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          { prop: "name", label: "商品名称", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.name}
                </span>
              );
            },
          },
          {prop:'categoryCode',label:'税收分类编码',center: 'left',},
          {prop:'spec',label:'规格型号',center: 'left',
          render: (h, data) => {
              return (
                <span>
                  {data.row.spec ? data.row.spec :'-'}
                </span>
              );
            },
          },
          {prop:'unit',label:'单位',center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.unit ? data.row.unit :'-'}
                </span>
              );
            },
          },
          {prop:'taxRate',label:'税率',center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.taxRate ? data.row.taxRate+'%' :'-'}
                </span>
              );
            },
          },
          { prop: "isFreeTax", label: "是否免税", center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.isFreeTax == '1' ?  '是' :'否'}
                </span>
              );
            },
          },
          { prop: "hasPreferPolicy", label: "是否享受优惠政策", center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.hasPreferPolicy == '1' ?  '是' :'否'}
                </span>
              );
            },
          },
          {
            label: "操作",
            center: 'left' ,
            render: (h, data) => {
              // if(data.row.isDefault == '1'){
              //   return (
              //     <span>
              //       <el-button class='button'  type="text" onClick={() => { this.edit(data.row);}}>
              //         编辑
              //       </el-button>
              //     </span>
              //   );
              // }else{
                return (
                  <span>
                    <el-button class='btn' type="text" v-auth="productInfo:edit" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                      <el-button class='btn' type="text" v-auth="productInfo:delete" onClick={() => { this.delete(data.row);}}>
                        删除
                      </el-button>
                  </span>
                );
              // }
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 10,
        isdeling: false,
        multipleSelection:[],

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',
        delDialog: false,
        exportVis: false,
      }
    },
    methods: {
      add() {
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.$router.push('productInfo/add')
      },
      edit(row) {
        this.$router.push({
          path: 'productInfo/edit',
          query: {
            id: row.id
          }
        })
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      //批量删除
      selectDel(){
        if (this.multipleSelection.length == 0) {
          einvAlert.error("提示",'请选中需要删除的数据！')
        } else{
          this.delDialog = true
          this.id = this.multipleSelection.map(item => {
                  return item.id
                }).join(',')
        }
      },
      //单选删除
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },
      confirmDialog() {
        this.isdeling = true
        this.$store.dispatch("DeleteCommodity", {
          ids: this.id,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.initTable()
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        })
      },
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      // 商品列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("FindCommodity", {
          name: this.queryValue,
          page: this.page,
          rows: this.pageSize,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      showExport(){
        this.exportVis = true
      },
      exportCancel(){
        this.exportVis = false;
      },
      exportConfirm(type){
        // console.log('提交',type);
        if(type == '1'){
          if (this.multipleSelection.length == 0) {
            einvAlert.warning('提示','请先勾选商品信息',true,'确定')
            return;
          } 
        }
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/einvoice/commodity/export',
          responseType: 'blob',
          params: {
            exportType: type,
            ids: this.multipleSelection.map(item => {
                  return item.id
                }).join(','),
            name: this.queryValue,
            companyId : this.getToken('companyId'),
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '商品信息管理' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
          einvAlert.error("提示",err)
        })
        this.exportVis=false;
      },
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 170px !important;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
